.ulStyle{
    display:flex;
    background:#3D67BC;
    height:67.5px;
    position:absolute;
    right:100px;
    line-height:67.5px;
    color:#fff;
    font-size:16px;
    justify-content:flex-end;
}
.loginStyle{
    display:flex;
    background:#3D67BC;
    height:67.5px;
    position:absolute;
    right:40px;
    line-height:67.5px;
    color:#fff;
    font-size:16px;
    justify-content:flex-end;
}
.style{
    width: 100%;
    position: absolute;
    top: 55px;
    left: 0px;
    z-index: 1;
    background: #fff;
    line-height: 1;
    color:#000;
    box-shadow: 0px 0px 10px #ccc;
    border-radius: 4px;
    font-size: 14px;
}
.span{
    display: none;
}
.user{
    display: block !important;
}
.loginStyles{
    display: none;
}
@media screen and (max-width:900px){
    .loginStyles{
        display: block;
        background:#3D67BC;
        /* height:270px; */
        width:100%;
        position:absolute;
        top: 67.5px;
        right:0px;
        line-height:67.5px;
        color:#fff;
        font-size:16px;
        z-index: 1;
    }
    .loginStyles>li{
        border-top: 1px solid #fff;
        height: 67.5px;
        width: 100% !important;
    }
    .loginStyles>li img{
        display: none;
    }
    .style{
        width: 100%;
        position: absolute;
        top: 55px;
        left: 0px;
        z-index: 10;
        background: #fff;
        color:#000;
        box-shadow: 0px 0px 10px #ccc;
        border-radius: 4px;
        font-size: 14px;
    }
    .span{
        display: block;
    }
    .user{
        display: none !important;
    }
}
.switchLanguage{
    position: absolute;
    right: 30px; 
    cursor: pointer;
}
.switchLanguage ul{
    width: 75px;
    position: absolute;
    top:50px;
    line-height: 67.5px;
    background: #fff;
    z-index: 10;
    box-shadow: 0px 0px 10px #ccc;
    border-radius: 5px;
}
.switchLanguage span:hover{
    color: #ccc;
    border-color: #ccc;
}
.switchLanguage ul li{
    line-height: 1;
    padding: 10px 0;
    color: #000;
}